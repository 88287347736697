<template>
	<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" style="background-color: #FFF; border-top-right-radius: 10px">
        <router-link to="/">
            <div class="navbar-header pointer text-center">
                <img src='/assets/img/logo-diri.png' class="img-fluid" alt="">
            </div>
        </router-link>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="margin-bottom: 50px;">
                <li class="nav-item pl-0" v-for="(data, idx) in menus" :key="idx" 
                    :class="isActive(data) + (data.children && data.children.length > 0 ? ' has-sub' : '') + (data.children && data.children.length > 0 && isActive(data) ? ' open' : '')">
                    <router-link :to="data.children && data.children.length > 0 ? '' : data.link">
                        <i class="material-icons f-25 ml-1" v-if="data.icon != 'icon-name'">{{data.icon}}</i>
                        <i class="ml-2 fa fa-circle" v-else></i>
                        <span class="menu-title align-self-center font-400">{{data.name}}</span>
                        <span class="badge badge badge-danger badge-pill float-right" v-if="data.counter > 0">{{data.counter}}</span>
                    </router-link>
                    <ul class="menu-content">
                        <li v-for="(dt, i) in data.children" :key="i">
                            <router-link :to="dt.link">
                                <i class="ml-2 fa fa-circle"></i>
                                <span class="menu-item">{{dt.name}}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import { SHOWN_MENUS } from '@/constant/menu';

export default {
    data() {
        return {
            menus: []
        }
    },
    methods: {
        isActive(data){
            let _ = this
            if (data.children && data.children.length > 0) {
                let isThere = data.children.filter(x => _.$route.fullPath.startsWith(x.link));
                if (isThere.length > 0) {
                    return 'active';
                }
            } else {
                if (data.link == _.$route.fullPath || _.$route.fullPath.indexOf(data.link) != -1){
                    return 'active';
                }
            }
        },
        getMenu() {
            let _ = this
            let data = JSON.parse(localStorage.getItem('account'))
            if (data) {
                _.menus = data.admin.role.menus
                .filter(menu => SHOWN_MENUS.includes(menu.link))
                .map(menu => ({
                    ...menu,
                    children: menu.children.filter(child => SHOWN_MENUS.includes(child.link))
                }));
            } else {
                window.location.assign('/login');
            }
        }
    },
    mounted() {
        let _ = this

        _.getMenu()

        _.$root.$on('changeMenuCounter', (data) => {
            _.menus[data.id_menu].counter -= data.counter
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterToZero', (data) => {
            _.menus[data.id_menu].counter = 0
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterTo', (data) => {
            _.menus[data.id_menu].counter = data.counter
            _.$forceUpdate();
        })
    },
}
</script>