<template>
	<div>
		<Navbar />
	    <Menu />
	    <div class="app-content content">
	        <div class="content-wrapper">
				<div class="content-body">
					<router-view/>
				</div>
	        </div>
	    </div>
		<div class="sidenav-overlay"></div>
		<Footer />
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
import Footer from '@/layouts/components/Footer'
import { SHOWN_MENUS } from '@/constant/menu';
import { isValidMenu } from '@/helper';
import cookies from 'js-cookie';

export default {
	components: {
		Navbar,
		Menu,
		Footer
	},
	beforeMount() {
        let _ = this
        let accessToken = cookies.get('accessTokenV1');
        if (accessToken) {       
            _.axios.get('/auth/me')
                .then(resp => {                                
                    const path = _.$router.history.current.path;
                    const filteredMenus = resp.data.data.admin.role.menus
											.filter(menu => SHOWN_MENUS.includes(menu.link))
											.map(menu => ({
												...menu,
												children: menu.children.filter(child => SHOWN_MENUS.includes(child.link))
											}));

                    if(!isValidMenu(path, filteredMenus)){
                        window.location.replace('/404');
                    }
                })
        } else{
						this.$store.dispatch('logout');
            window.location.replace('/login');
        }}
}
</script>